.app-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-left: 5rem;
  padding: 0;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

h1 {
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 4rem;
  font-weight: 500;
}

h2 {
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  opacity: 0.5;
}

.subtitle {
  display: flex;
  align-items: center;
  font-family: Inter;
  margin: 0;
  padding: 0;
}

.subCategories {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.9rem;
  }
}
